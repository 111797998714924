import React from "react";
import { Link, navigate } from "gatsby";
import Container from "./container";
import { format } from "date-fns";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { getPageUrl } from "../lib/helpers";
import Icon from "./icon";
import PortableText from "./portableText";
import PostPagination from "./pagination";

import * as styles from "../styles/components/update-main.module.css";

const UpdateMain = (props) => {

  const {
    title,
    publicationDate,
    _rawContent,
    featuredImage,
    prevUpdate,
    nextUpdate
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <Link to="/updates" className={cn(styles.postNavigationLink, styles.prev, styles.topBack)}>
          <Icon symbol="chevron-left" />
          Updates
        </Link>
        <div className={styles.inner}>
          {(title || (featuredImage && featuredImage.asset)) && (
            <div className={styles.header}>
              {publicationDate && (
                <span className={styles.date}>{format(new Date(publicationDate).setDate(new Date(publicationDate).getDate() + 1), "MM/dd/yy")}</span>
              )}
              {title && (
                <h1 className={styles.title}>{title}</h1>
              )}
              {featuredImage && featuredImage.asset && (
                <div className={styles.image}>
                  <img
                    src={imageUrlFor(buildImageObj(featuredImage))
                      .auto("format")
                      .url()}
                    alt={featuredImage.alt}
                  />
                </div>
              )}
            </div>
          )}
          {_rawContent && (
            <div className={styles.content}>
              <PortableText blocks={_rawContent} />
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className="grid justify-between">
            <div className="col-auto">
              {prevUpdate && (
                <Link to={getPageUrl(prevUpdate.slug)} className={cn(styles.postNavigationLink, styles.prev)}>
                  <Icon symbol="chevron-left" />
                  Previous
                </Link>
              )}
            </div>
            <div className="col-auto">
              <button className={cn(styles.postNavigationLink, styles.back)} onClick={() => navigate(-1)}>
                <Icon symbol="back" />
                Back
              </button>
            </div>
            <div className="col-auto">
              {nextUpdate && (
                <Link to={getPageUrl(nextUpdate.slug)} className={cn(styles.postNavigationLink, styles.next)}>
                  Next
                  <Icon symbol="chevron-right" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UpdateMain;
