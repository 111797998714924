import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import UpdateMain from "../components/update-main";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query UpdateTemplateQuery($id: String!) {
    update: sanityUpdate(id: { eq: $id }) {
      id
      title
      publicationDate
      _rawContent
      description
      featuredImage {
        ...SanityImage
        alt
      }
    }
  }
`;

const UpdateTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { prev, next } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const update = (data || {}).update;

  return (
    <Layout>
      <Seo
        title={update.title}
        description={update.description}
        image={update.featuredImage}
      />

      {(update.title || (update.featuredImage && update.featuredImage.asset) || update._rawContent) && (<UpdateMain {...update} prevUpdate={prev} nextUpdate={next} />)}
    </Layout>
  );
};

export default UpdateTemplate;
