// extracted by mini-css-extract-plugin
export var back = "update-main-module--back--oTfiu";
export var content = "update-main-module--content--D5KvB";
export var date = "update-main-module--date--NgXem";
export var footer = "update-main-module--footer--nE6qd";
export var header = "update-main-module--header--3JM1D";
export var image = "update-main-module--image--lIhpz";
export var inner = "update-main-module--inner--nbGXW";
export var next = "update-main-module--next--vBJY7";
export var postNavigationLink = "update-main-module--postNavigationLink--7Ktg6";
export var prev = "update-main-module--prev--6OXuQ";
export var root = "update-main-module--root--9oTcu";
export var title = "update-main-module--title--Qo-4w";
export var topBack = "update-main-module--topBack--YIuZ+";